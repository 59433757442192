import http from "../plugins/axios";

export const login = async (payload) => {
  try {
    const response = await http.post(`/auth/login`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const refreshToken = async (payload) => {
  try {
    const response = await http.post(`/auth/refresh`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPermissionRole = async () => {
  try {
    const response = await http.get(`/auth/permissions`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (payload) => {
  try {
    const response = await http.post(`/password-reset/request/org`, payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (token, payload) => {
  try {
    const response = await http.post(`/password-reset/verify?token=${token}`, payload);
    return response;
  } catch (error) {
    throw error;
  }
};
