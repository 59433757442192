import React, { useEffect, useState } from "react";
import { Anchor, Badge, Box, Button, Card, Group, Image, Text, TextInput, rem } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { validation } from "../../plugins/validation";
import { notificationError, notificationSuccess } from "../../components/ui/Notification";
import { forgotPassword } from "../../services/auth";
import Cookies from "universal-cookie";

// mantine ui & image
import classes from "./forgotPassword.module.css";
import Logo from "../../assets/img/logo-tandeem.png";

const initialVal = {
  nickname: "",
  email: "",
};

const formValidation = {
  nickname: {
    isError: false,
    message: "",
  },
  email: {
    isError: false,
    message: "",
  },
};

const ForgotPassword = () => {
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [errMessage, setErrMessage] = useState(null);
  const isMobile = useMediaQuery("(max-width: 430px)");

  useEffect(() => {
    cookies.remove("token");
    cookies.remove("refreshToken");
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setValidationForm(formValidation);
    const isErrorForm = validation(formData, setValidationForm);
    if (isErrorForm) {
      setLoading(false);
      return;
    }
    try {
      const response = await forgotPassword(formData);
      if (response) {
        notificationSuccess("Periksa Email Anda!", `${response.data.message}`);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      setErrMessage(errorMessage);
      notificationError("Reset Password Gagal Terkirim", `${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.body}>
      <Box className={classes.card}>
        <Card shadow="sm" padding="lg" w={isMobile ? 280 : 400}>
          <Box className={classes.logo}>
            <Image src={Logo} alt="logo" />
          </Box>

          <Group mt="md" mb="xs">
            <Text fw={400} size="sm">
              Masukkan nickname organisasi dan email untuk mengatur ulang password Anda!
            </Text>
          </Group>

          <Box>
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <Box mb={20}>
                <Box mb="sm">
                  <TextInput name="nickname" label="Nickname" placeholder="Masukkan nickname" error={validationForm.nickname.isError ? `${validationForm.nickname.message}` : ""} onChange={handleChange} withAsterisk />
                </Box>
                <Box mb="sm">
                  <TextInput name="email" label="Email" placeholder="Masukkan email" error={validationForm.email.isError ? `${validationForm.email.message}` : ""} onChange={handleChange} withAsterisk />
                </Box>

                {errMessage && (
                  <Box mb="sm" ta="center">
                    <Badge size="md" variant="outline" color="red" radius="lg" fw={600} border={`${rem(1)} solid red`}>
                      {errMessage}
                    </Badge>
                  </Box>
                )}
              </Box>
              <Box>
                <Group grow>
                  <Button className={classes.button} disabled={!formData.email || !formData.nickname} variant="light" radius="sm" size="md" loading={loading} color="#0572b9" type="submit">
                    Submit
                  </Button>
                </Group>
              </Box>
              <Box>
                <Group mb="xs" mt="sm" justify="center">
                  <Anchor size="sm" fw={600} c="blue" className={classes.textLink} href="/authentication/sign-in">
                    Kembali ke halaman Sign In
                  </Anchor>
                </Group>
              </Box>
            </form>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
