import http from "../plugins/axios";

export const getListHistoryWeb = async (params) => {
  try {
    const response = await http.get(`/history-web`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};
