import React, { useEffect, useState } from "react";
import classes from "./permissionByApp.module.css";
import Sidebar from "../../../components/ui/AppShell/Sidebar";
import { Box, Button, Flex, Modal, Pagination, ScrollArea, Text, TextInput, rem } from "@mantine/core";
import { IconCopyPlus, IconSearch } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { useLocation } from "react-router-dom";
import { getListPermissionAllDefault } from "../../../services/permissionDefault";
import TablePermissionDefault from "../../../components/pages/permissionDefault/TablePermissionDefault";
import TableSkeleton from "../../../components/ui/TableSkeleton";
import FormPermission from "../../../components/pages/permissionDefault/FormPermission";

const defaultParameter = {
  applicationId: "",
  skip: 0,
  take: 10,
  keyword: "",
  orderBy: "name",
  order: "asc",
};

const labelTable = [
  {
    label: "No.",
    width: 30,
  },
  {
    label: "Nama",
    width: "auto",
  },
  {
    label: "Alias",
    width: "auto",
  },
  {
    label: "Application",
    width: "auto",
  },
  {
    label: "Status",
    width: "auto",
  },
  {
    label: "For All",
    width: "auto",
  },
  {
    label: "Action",
    width: 100,
  },
];

const PermissionDefaultByApp = () => {
  const [params, setParams] = useState(defaultParameter);
  const [permissionDefaultList, setPermissionDefaultList] = useState([]);
  const [count, setCount] = useState(0);
  const [detailData, setDetailData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 425px)");
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];
  const appName = permissionDefaultList?.[0]?.applicationPermission?.[0]?.application?.name;

  const handleGetPermissionDefaultList = async () => {
    setLoading(true);
    try {
      const response = await getListPermissionAllDefault(params);
      setPermissionDefaultList(response.data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setParams((prevParams) => ({ ...prevParams, applicationId: id }));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    handleGetPermissionDefaultList();
    // eslint-disable-next-line
  }, [params]);

  const onCloseModalForm = () => {
    setOpenModalForm(false);
    setDetailData(null);
  };

  const handleSearchChange = (e) => {
    const params = {
      applicationId: id,
      skip: 0,
      take: 10,
      keyword: e.target.value,
      orderBy: "name",
      order: "asc",
    };
    setParams(params);
  };

  const handleChangePage = (e) => {
    const params = {
      applicationId: id,
      skip: (e - 1) * 10,
      take: 10,
      keyword: "",
      orderBy: "name",
      order: "asc",
    };
    setParams(params);
  };

  const actions = {
    edit: (val) => {
      setOpenModalForm(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box>
        <Text className={classes.titlePage} mb={20}>
          Permission Management {appName}
        </Text>
      </Box>

      <Box style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
        <Flex>
          <TextInput leftSectionPointerEvents="none" leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} /> placeholder="Cari Permission. . ." name="search" onChange={handleSearchChange} />
        </Flex>
        <Flex mt={isSmallScreen ? 10 : 0}>
          <Button fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#0572b9" onClick={() => setOpenModalForm(true)}>
            Tambah Permission
          </Button>
        </Flex>
      </Box>
      <Box my={20}>{loading ? <TableSkeleton total={5} /> : <TablePermissionDefault label={labelTable} data={permissionDefaultList} countData={params.skip} actionMethod={handleAction} />}</Box>
      <Flex justify="end">
        <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#0572b9" radius="lg" />
      </Flex>
      <Modal
        opened={openModalForm}
        onClose={onCloseModalForm}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={<Text fw="Bold">{detailData ? "Edit Permission" : "Tambah Permission"}</Text>}
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={false}
      >
        <FormPermission dataPermission={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetPermissionDefaultList} />
      </Modal>
    </Sidebar>
  );
};

export default PermissionDefaultByApp;
