import http from "../plugins/axios";

export const getListPermissionDefault = async (id) => {
  try {
    const response = await http.get(`/permissions?applicationId=${id || ""}`);
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getListPermissionAllDefault = async (params) => {
  try {
    const response = await http.get(`/permissions/all`, { params });
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getListPermissionOrg = async (id) => {
  try {
    const response = await http.get(`/permissions?applicationId=${id || ""}`);
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addPermissionDefault = async (payload) => {
  try {
    const response = await http.post(`/permissions`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePermissionDefault = async (id, payload) => {
  try {
    const response = await http.patch(`/permissions/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
