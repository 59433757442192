import React from "react";
import moment from "moment-timezone";
import { NumericFormat } from "react-number-format";

export const dateFormatter = (val) => {
  const inputDate = new Date(val);
  const formatter = moment(inputDate).format();
  return formatter;
};

export const dateFormatterLocal = (val) => {
  const inputDate = new Date(val);
  const formatter = moment(inputDate).format("DD-MM-YYYY");
  return formatter;
};

export const parseDate = (dateString) => {
  const date = new Date(dateString); // Create a new date object from the date string
  const year = date.getFullYear(); // Get the year (e.g. 2022)
  const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g. 3 for April)
  const day = date.getDate(); // Get the day of the month (e.g. 17)

  // Add leading zeros to month and day if they are single-digit numbers
  const formattedMonth = month.toString().padStart(2, "0"); // Add leading zero to month (e.g. '04')
  const formattedDay = day.toString().padStart(2, "0"); // Add leading zero to day (e.g. '17')

  return `${year}-${formattedMonth}-${formattedDay}`; // Return formatted date string (e.g. '2022-04-17')
};

export const parseMonth = (dateString) => {
  const date = new Date(dateString); // Create a new date object from the date string
  const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g. 3 for April)

  return `${month}`; // Get the month (0-indexed, so add 1) (e.g. 3 for April)
};

export const parseYear = (dateString) => {
  const date = new Date(dateString); // Create a new date object from the date string
  const year = date.getFullYear(); // Get the year (e.g. 2022)

  return `${year}`; // Return formatted Get the year (e.g. 2022)
};

export const parseDateLocal = (dateString) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  // gives you your current date
  const today = new Date(dateString);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let mmm = monthNames[today.getMonth()];
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + " " + mmm + " " + yyyy;
  return formattedToday;
};

export function getFileFromBase64(string64, fileName) {
  return new Promise((resolve) => {
    fetch(string64)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], fileName, { type: "image/png" });
        return resolve(file);
      });
  });
}

export const getFirstDayOfMonth = () => {
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  return dateFormatter(firstDayOfMonth);
};

export const getLastDayOfMonth = () => {
  const today = new Date();
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  const lastDayOfMonth = new Date(nextMonth - 1);
  return dateFormatter(lastDayOfMonth);
};

export const TimeFormatter = (timeString) => {
  if (!timeString) return "";
  const [hours, minutes] = timeString.split(":");
  const time = new Date();
  time.setHours(parseInt(hours));
  time.setMinutes(parseInt(minutes));
  const options = { hour: "numeric", minute: "numeric", hour12: false };
  const formatTime = time.toLocaleTimeString(undefined, options);
  return formatTime;
};

export const formatedTime = (value) => {
  const d = new Date(value);
  const hours = d.getHours();
  const minutes = "0" + d.getMinutes();

  const formattedTime = hours + ":" + minutes.slice(-2);
  return formattedTime;
};

export const CurrencyFormat = (value) => {
  const formattedValue = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(value);
  return formattedValue;
};

export const numberWithCommas = (value) => {
  return value?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
};

export const MonthNameFormat = (value) => {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const getMonthName = monthNames[value - 1];
  return getMonthName;
};

export const MonthName = (value) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const getMonthName = monthNames[value - 1];
  return getMonthName;
};

export const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="Rp "
    />
  );
});
