import React from "react";
import classes from "./sidebarMenu.module.css";
import { Box, Text, ThemeIcon } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../../route";
import { useSelector } from "react-redux";

const SidebarMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const routeName = useLocation().pathname.split("/").slice(1);
  const listPermission = useSelector((state) => state.permission.access);
  const dataUser = useSelector((state) => state.auth.dataUser);
  // console.log(dataUser);

  const camelToDashCase = (str) => {
    if (str) {
      return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
    } else {
      return str;
    }
  };

  const mappingMenuSidebar = (listPermission) => {
    if (listPermission !== null) {
      // eslint-disable-next-line
      const renderRoute = routes.map(({ name, route, icon, type, permission, adminPermission, superadminOrgPermission, nosidebar }, index) => {
        let returnRender = null;
        const dataPermission = Object.keys(listPermission);
        dataPermission.push("dashboard");

        const isAllowed = dataPermission.includes(camelToDashCase(permission));

        if (type === "route") {
          // const routeWithoutId = routeName[0];
          returnRender = (
            <a
              className={classes.link}
              key={index}
              href={route}
              data-active={route === pathname || undefined}
              onClick={(event) => {
                event.preventDefault();
                navigate(`${route}`);
              }}
            >
              <ThemeIcon variant="light" className={classes.linkIcon} data-active={route === pathname || undefined} radius="md" size="md">
                {icon}
              </ThemeIcon>
              <span style={{ textTransform: "capitalize" }}>{name}</span>
            </a>
          );
        } else if (type === "title") {
          returnRender = (
            <Text fz={12} fw="bold" c="gray.5" ml={10} tt="uppercase" my={12} key={index}>
              {name}
            </Text>
          );
        }

        if (nosidebar) {
          return null;
        }

        if (adminPermission && superadminOrgPermission) {
          if (dataUser?.scope === adminPermission) {
            return returnRender;
          } else if (dataUser?.scope === superadminOrgPermission) {
            return returnRender;
          } else {
            return null;
          }
        }

        if (adminPermission) {
          if (dataUser?.scope === adminPermission) {
            return returnRender;
          } else {
            return null;
          }
        }

        if (permission) {
          if (isAllowed) {
            return returnRender;
          } else {
            return null;
          }
        }
      });
      return renderRoute;
    }
  };
  return <Box p="lg">{mappingMenuSidebar(listPermission)}</Box>;
};

export default SidebarMenu;
