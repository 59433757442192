import http from "../plugins/axios";

export const getListUser = async (params) => {
  try {
    const response = await http.get(`/users`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailUser = async (id) => {
  try {
    const response = await http.get(`/users/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addUser = async (payload) => {
  try {
    const response = await http.post(`/users/create`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (id, payload) => {
  try {
    const response = await http.patch(`/users/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await http.delete(`/users/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
